import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import checkIcon from 'browser/assets/icons/check.svg';
import PropTypes from 'prop-types';

const TERMS = {
  pl: [
    {
      formTermsText:
        'Chcę otrzymywać od Matexi Polska Sp. z o.o. informacje o promocjach, ofertach i inne informacje handlowe, co do produktów i usług oferowanych przez spółki z Grupy Matexi za pośrednictwem:',
    },
    {
      formTermsText: 'poczty elektronicznej (e-mail)',
    },
    {
      formTermsText: 'telefonu (w tym SMS, MMS)',
    },
    {
      formTermsText:
        'Zapoznałem/am się z <a href="/polityka-prywatnosci">Polityką Prywatności Matexi Polska Sp. z o.o.</a>*',
    },
  ],
  en: [
    {
      formTermsText:
        'I give consent to the processing of my personal data for the purposes of my inquiry or presentation of the offer. The consent is voluntary, but required to allow us to contact you to respond to your inquiry and present our offer. If you do not wish to be contacted by e-mail or telephone, please visit our nearest sales office.*',
    },
    {
      formTermsText:
        'I hereby give consent to Matexi Polska sp. z o.o. to use telecommunication terminal devices and automated dialing systems, i.e. telephone and email, for marketing purposes in the meaning of the Act of 16 July 2014 - Telecommunications',
    },
    {
      formTermsText:
        'I hereby give consent to Matexi Polska sp. z o.o. to process my personal data for marketing purposes, including for the purpose of informing me about the current offer.',
    },
    {
      formTermsText:
        'I hereby give consent to receive trade information from Matexi Polska sp. z o.o. in the meaning of the Act of 18 July 2002 on the provision of marketing services by electronic means.',
    },
  ],
};

export const Label = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 30px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 1.2rem;
  text-align: left;

  a {
    color: inherit;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }

  :not(:first-of-type) {
    margin-bottom: 8px;
  }

  &:first-of-type {
    margin-bottom: 13px;
    & > p {
      padding-top: 3px;
    }
  }

  &:nth-of-type(3),
  &:nth-of-type(4) {
    margin-left: 12px;
  }
`;

const LabelBtn = styled.button`
  background: transparent;
  color: #182a39;
  font-family: Inter, sans-serif;
  cursor: pointer;
  align-self: flex-start;
  padding: 2px 0 4px 10px;
  margin-left: auto;
`;

const LabelText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
      padding-top: 2px;
      word-break: normal;
    `}
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: ${({ big }) => (big ? '25px' : '20px')};
  width: ${({ big }) => (big ? '25px' : '20px')};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  transition: 0.2s;
  border-color: ${({ theme, error }) => (error ? '#DD4363' : '#CFCFCF')};
  &:after {
    content: '';
    position: absolute;
    opacity: 0;
    transition: 0.2s;
    left: ${({ big }) => (big ? '6px' : '3px')};
    top: ${({ big }) => (big ? '5px' : '3px')};
    width: 12px;
    height: 12px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark} {
    background: #ebf6ff;
    border-color: #ebf6ff;
    &:after {
      opacity: 1;
    }
  }
`;

const StyledLabelText = styled(LabelText)`
  font-weight: ${({ theme }) => theme.bold};
`;

const Terms = ({
  setFieldValue,
  setFieldTouched,
  values,
  setErrors,
  errors,
  submitClicked,
}) => {
  const finalTerms = TERMS.pl.map(({ formTermsText }, index) => ({
    name: `agree${index + 1}Browser`,
    content: formTermsText,
    required: index === 3,
  }));

  const [expandedTerms, setExpandedTerms] = useState([]);

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const acceptAllKey = 'agreeAllBrowser';
  const agree1Key = 'agree1Browser';
  const agree4Key = 'agree4Browser';

  return (
    <>
      <Label htmlFor={acceptAllKey}>
        <StyledLabelText>Zaznacz wszystkie zgody</StyledLabelText>
        <Checkbox
          id={acceptAllKey}
          name={acceptAllKey}
          type="checkbox"
          onChange={() => {
            if (!finalTerms.every(term => values[term.name] === true)) {
              setFieldValue(acceptAllKey, true);
              finalTerms.forEach(term => setFieldValue(term.name, true));
            } else {
              setFieldValue(acceptAllKey, true);
              finalTerms.forEach(term => setFieldValue(term.name, false));
            }
          }}
          checked={finalTerms.every(term => values[term.name] === true)}
        />
        <Checkmark />
      </Label>
      {finalTerms.map(({ name, content, required }) => (
        <Label htmlFor={name} key={name}>
          <LabelText
            expanded={expandedTerms.includes(name)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {/* <LabelBtn type="button" onClick={() => handleCheckbox(name)}>
            {expandedTerms.includes(name) ? 'Mniej' : 'Więcej'}
          </LabelBtn> */}
          <Checkbox
            id={name}
            type="checkbox"
            onChange={() => {
              const isParentAgreement = name.includes('1');
              const isSubAgreement = name.includes('2') || name.includes('3');

              if (!isParentAgreement && !isSubAgreement) {
                setFieldValue(name, !values[name]);
                if (required) {
                  setErrors({ ...errors, [agree1Key]: '' });
                }
                return;
              }

              const parentAgreementSelected = values.agree1Browser;

              if (isParentAgreement) {
                if (!parentAgreementSelected) {
                  setFieldValue(`agree1Browser`, true);
                  setFieldValue(`agree2Browser`, true);
                  setFieldValue(`agree3Browser`, true);
                  setFieldTouched(`agree1Browser`, true);
                  setFieldTouched(`agree2Browser`, true);
                  setFieldTouched(`agree3Browser`, true);
                } else {
                  setFieldValue(`agree1Browser`, false);
                  setFieldValue(`agree2Browser`, false);
                  setFieldValue(`agree3Browser`, false);
                  setFieldTouched(`agree1Browser`, false);
                  setFieldTouched(`agree2Browser`, false);
                  setFieldTouched(`agree3Browser`, false);
                }
              }

              if (isSubAgreement) {
                const isCurrentSubAgreementSelected = values[name];
                const bothSubAgreementsSelected =
                  values.agree2Browser && values.agree3Browser;

                if (parentAgreementSelected) {
                  if (bothSubAgreementsSelected) {
                    setFieldValue(name, !values[name]);
                    setFieldTouched(name, true);
                    return;
                  }
                  if (isCurrentSubAgreementSelected) {
                    setFieldValue(`agree1Browser`, false);
                    setFieldValue(name, !values[name]);
                    setFieldTouched(name, true);
                    setFieldTouched(`agree1Browser`, false);
                  } else {
                    setFieldValue(name, !values[name]);
                    setFieldTouched(name, true);
                  }
                } else if (!parentAgreementSelected) {
                  setFieldValue(`agree1Browser`, true);
                  setFieldValue(name, !values[name]);
                  setFieldTouched(`agree1Browser`, true);
                  setFieldTouched(name, true);
                }
              }
            }}
            checked={values[name]}
          />
          <Checkmark
            error={
              submitClicked &&
              errors[agree4Key] &&
              required &&
              values[name] === false
            }
          />
        </Label>
      ))}
    </>
  );
};

Terms.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ).isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  submitClicked: PropTypes.bool.isRequired,
};

export default Terms;
